import LazyIframe from "../../../../src/components/LazyIframe";
import YoutubePlayer from "../../../../src/components/YoutubePlayer";
import InlineRelated from "../../../../src/components/InlineRelated";
import InlineAd from "../../../../src/components/InlineAd";
import InlineAffiliateAd from "../../../../src/components/InlineAffiliateAd";
import Strawpoll from "../../../../src/components/Strawpoll";
import * as React from 'react';
export default {
  LazyIframe,
  YoutubePlayer,
  InlineRelated,
  InlineAd,
  InlineAffiliateAd,
  Strawpoll,
  React
};