import Link from "gatsby-link";
import InlineRelated from "../../../../src/components/InlineRelated";
import InlineAffiliateAd from "../../../../src/components/InlineAffiliateAd";
import LazyIframe from "../../../../src/components/LazyIframe";
import YoutubePlayer from "../../../../src/components/YoutubePlayer";
import InlineAd from "../../../../src/components/InlineAd";
import * as React from 'react';
export default {
  Link,
  InlineRelated,
  InlineAffiliateAd,
  LazyIframe,
  YoutubePlayer,
  InlineAd,
  React
};