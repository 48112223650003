import React, { Component } from "react";
import LazyIframe from "../components/LazyIframe";

export default class YoutubePlayer extends Component {
  constructor(props) {
    super(props);

    this.youtubeSrc = props.src;

  }


  render() {
    return (
      <div>
          <LazyIframe key="7b69a3d2-1665-4bef-841c-4a73b34af176" width="100%" height="375" src={this.youtubeSrc} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></LazyIframe>
          <div class="g-ytsubscribe" data-channelid="UC0fspKfWI99xkoh-RcsNFew" data-layout="full" data-count="default"></div>
      </div>
    );
  }
}
