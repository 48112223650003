import { OutboundLink } from "gatsby-plugin-google-analytics";
import LazyIframe from "../../../../src/components/LazyIframe";
import InlineRelated from "../../../../src/components/InlineRelated";
import InlineAd from "../../../../src/components/InlineAd";
import InlineAffiliateAd from "../../../../src/components/InlineAffiliateAd";
import * as React from 'react';
export default {
  OutboundLink,
  LazyIframe,
  InlineRelated,
  InlineAd,
  InlineAffiliateAd,
  React
};