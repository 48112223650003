import Link from "gatsby-link";
import YoutubePlayer from "../../../../src/components/YoutubePlayer";
import InlineRelated from "../../../../src/components/InlineRelated";
import InlineAd from "../../../../src/components/InlineAd";
import Strawpoll from "../../../../src/components/Strawpoll";
import * as React from 'react';
export default {
  Link,
  YoutubePlayer,
  InlineRelated,
  InlineAd,
  Strawpoll,
  React
};