import { trackCustomEvent } from "gatsby-plugin-google-analytics";
import React, { useEffect, useState } from "react";
const squareSidebar = {
  marginTop: "10px",
  width: "300px",
  height: "250px",
  cursor: "pointer",
};

const squareContent = {
  marginTop: "40px",
  marginBottom: "50px",
  cursor: "pointer",
};

const horizontalSidebar = {
  marginTop: "10px",
  width: "300px",
  height: "1500px",
};
const horizontalContent = {
  marginTop: "10px",
  minWidth: "300px",
  width: "auto",
  height: "150px",
};

const colStyle = {
  position: "relative",
  height: "inherit",
  paddingTop: "20px",
};

const imageStyle = {
  maxWidth: "100%",
  maxHeight: "100%",
};

const overlayContainer = {
  position: "absolute",
  height: "100%",
  backgroundColor: "#fcf8f5",
  width: "55%",
  top: "0",
  left: "0",
  bottom: "50px",
  zIndex: "2",
  display: "block",
  fontSize: "18px",
  padding: "15px 10px 15px 15px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
};

const overlaySiteName = {
  maxWidth: "55%",
  marginBottom: "auto",
  // width: "100px",
  // height: "50px",
};
const overlayText = {
  marginBottom: "10px",
  fontSize: "18px",
  color: "black",
  fontWeight: "bold",
  width: "100%",
  display: "block",
  lineHeight: "22px",
  marginTop: "10px",
  // paddingRight:"5px",
  // textShadow:
  //   "0.05em 0 black, 0 0.05em black, -0.05em 0 black, 0 -0.05em black,-0.05em -0.05em black, -0.05em 0.05em black, 0.05em -0.05em black,0.05em 0.05em black",
  width: "100%",
};

const overlayPrice = {
  fontSize: "15px",
  fontWeight: "bold",
  marginTop: "auto",
  color: "gray",
};

const buttonStyle = {
  border: "none",
  color: "white",
  fontWeight: "bold",
  backgroundColor: "#F34750",
  width: "105px",
  heigth: "40px",
  padding: "8px",
  marginTop: "5px",
  borderRadius: "3px",
  fontSize: "15px",
};

function InlineAffiliateAd(props) {
  const [affiliateObj, setState] = useState([]);
  // horizontal
  // square
  const type = props.type || "square";
  // identification to know what ads was clicked
  const identifier = props.id;
  // content or sidebar;
  const position = props.position || "content";

  const customEvent = {
    // track what url was clicked on which placeholder identifier
    category: "Affiliate",
    action: "Click",
    label: identifier,
  };
  useEffect(() => {
    // filter list of possible ads by type
    // for now, if no type, assume it's square since they are all like that, it's pointless to even have that property
    const affiliatesOfType = affiliates.filter(
      (aff) => !aff.type || aff.type === type
    );
    const randomIndex = Math.floor(
      Math.random() * Object.keys(affiliatesOfType).length
    );
    const obj = affiliatesOfType[randomIndex];
    setState(obj);

    customEvent.value = obj.url;
  }, []);

  const trackEvent = (e) => {
    e.preventDefault();
    trackCustomEvent(customEvent);
    window.open(affiliateObj.url, "_blank");
  };

  function createMarkup() {
    return { __html: affiliateObj.content };
  }

  return (
    <React.Fragment>
      {affiliateObj ? (
        <React.Fragment>
          {type === "horizontal" ? (
            /* horizontal */
            <div
              className="row align-center"
              style={
                position === "sidebar" ? horizontalSidebar : horizontalContent
              }
            >
              {/* <div
             style={colStyle}
             className={position === "sidebar" ? "col-10" : "col-10 col-md-6"}
             onClick={(e) => trackEvent(e)}
           >
             <img
               style={imageStyle}
               src={affiliateObj.imagePath}
               alt="Image"
             ></img>
           </div> */}
            </div>
          ) : (
            /* square */
            <div
              className="row align-center"
              style={position === "sidebar" ? squareSidebar : squareContent}
            >
              {affiliateObj.isPremadeBanner ? (
                <div
                  style={{ width: "300px", height: "250px" }}
                  dangerouslySetInnerHTML={createMarkup()}
                >
                  {/* a premade banner from them  */}
                </div>
              ) : (
                // our own banner
                <div
                  style={{
                    //backgroundPosition: "center",
                    width: "300px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    height: "250px",
                    position: "relative",
                    backgroundSize: "cover",
                    backgroundImage: "url(" + affiliateObj.imagePath + ")",
                    boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.75)",
                  }}
                  className={
                    position === "sidebar" ? "col-12" : "col-10 col-md-6"
                  }
                  onClick={(e) => trackEvent(e)}
                >
                  <div style={overlayContainer}>
                    <img
                      style={overlaySiteName}
                      src={affiliateObj.siteLogo}
                    ></img>
                    {/* <span style={overlaySiteName}>{affiliateObj.siteName}</span> */}
                    <span style={overlayText}>{affiliateObj.title}</span>
                    {affiliateObj.price ? (
                      <span style={overlayPrice}>{affiliateObj.price}</span>
                    ) : null}
                    <button style={buttonStyle}>Learn Now</button>
                  </div>
                </div>
              )}
            </div>
          )}
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
}

export default InlineAffiliateAd;

// path is from the static folder root, so in this case, back twice

const affiliates = [
  // transferwise
  {
    // siteLogo: "../../affiliates/transferwise.png",
    // price: "From $16.66",
    url: "https://wise.prf.hn/click/camref:1011ljaa3/creativeref:1011l34332",
    content: `<a target="_blank" href="https://wise.prf.hn/click/camref:1011ljaa3/creativeref:1011l34332"><img src="https://wise-creative.prf.hn/source/camref:1011ljaa3/creativeref:1011l34332" width="300" height="250" border="0"/></a>`,
    isPremadeBanner: true,
    // title: "Python Data Analysis and Visualization",
    imagePath: "../../affiliates/transferwise.png",
    type: "square",
    label: "Wise Promotion",
    category: "Finance",
  },
  // educative
  {
    siteLogo: "../../affiliates/educative-logo.png",
    price: "From $16.66",
    url: "https://www.educative.io/path/become-front-end-developer?aff=BqmB",
    title: "Become a Front End Developer",
    imagePath: "../../affiliates/educative_front_end.png",
    type: "square",
    category: "Programming",
  },
  {
    siteLogo: "../../affiliates/educative-logo.png",
    price: "From $16.66",
    url: "https://www.educative.io/path/python-data-analysis?aff=BqmB",
    title: "Python Data Analysis and Visualization",
    imagePath: "../../affiliates/educative_python_data_analysis.png",
    type: "square",
    category: "Programming",
  },
  // {
  //   siteLogo: "../../affiliates/educative-logo.png",
  //   price: "From $16.66",
  //   url: "https://www.educative.io/courses/algorithms-ds-interview?aff=BqmB",
  //   title: "Algorithms and Data Structures Interview Crash Course",
  //   imagePath: "../../affiliates/educative_algorithm.webp",
  //   type: "square",
  //   category: "Programming",
  // },
  // {
  //   siteLogo: "../../affiliates/educative-logo.png",
  //   price: "From $16.66",
  //   url:
  //     "https://www.educative.io/courses/getting-started-with-angular?aff=BqmB",
  //   title: "The Angular Masterclass",
  //   imagePath: "../../affiliates/educative_angular.webp",
  //   type: "square",
  //   category: "Programming",
  // },
  {
    siteLogo: "../../affiliates/educative-logo.png",
    price: "From $16.66",
    url:
      "https://www.educative.io/courses/complete-guide-to-modern-javascript/?aff=BqmB",
    title: "Complete Guide to Modern JavaScript",
    imagePath: "../../affiliates/educative_javascript.webp",
    type: "square",
    category: "Programming",
  },
  // {
  //   siteLogo: "../../affiliates/educative-logo.png",
  //   price: "From $16.66",
  //   url: "https://www.educative.io//?aff=BqmB",
  //   title: "Learn in-demand tech skills in half the time",
  //   imagePath: "../../affiliates/educative_general.png",
  //   type: "square",
  //   category: "Programming",
  // },

  // udemy
  {
    type: "square",
    url:
      "https://click.linksynergy.com/fs-bin/click?id=m4*xMPdu*tc&offerid=507388.16256&subid=0&type=4",
    content: `<a href="https://click.linksynergy.com/fs-bin/click?id=m4*xMPdu*tc&offerid=507388.16256&subid=0&type=4" target="_blank"><IMG style="width:300px;height:250px" border="0" alt="24-Hour Flash Sale. Courses from just $11.99" src="https://ad.linksynergy.com/fs-bin/show?id=m4*xMPdu*tc&bids=507388.16256&subid=0&type=4&gridnum=13"></a>`,
    isPremadeBanner: true,
    label: "Udemy Promotion",
    category: "Programming",
  },
  {
    siteLogo: "../../affiliates/udemy-logo.png",
    title: "The Complete 2021 Web Development Bootcamp",
    price: "From $12.99",
    imagePath: "../../affiliates/udemy_web_bootcamp_1.jpg",
    url:
      "https://click.linksynergy.com/deeplink?id=m4*xMPdu*tc&mid=39197&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Fthe-complete-web-development-bootcamp%2F",
    category: "Programming",
  },
  // {
  //   siteLogo: "../../affiliates/udemy-logo.png",
  //   title: "Ultimate AWS Certified Developer Associate 2021",
  //   price: "From $12.99",
  //   imagePath: "../../affiliates/udemy_aws_1.jpg",
  //   url:
  //     "https://click.linksynergy.com/deeplink?id=m4*xMPdu*tc&mid=39197&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Faws-certified-developer-associate-dva-c01%2F",
  //   category: "Programming",
  // },
  // {
  //   siteLogo: "../../affiliates/udemy-logo.png",
  //   title: "2021 Complete Python Bootcamp",
  //   price: "From $12.99",
  //   imagePath: "../../affiliates/udemy_python_1.jpg",
  //   url:
  //     "https://click.linksynergy.com/deeplink?id=m4*xMPdu*tc&mid=39197&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Fcomplete-python-bootcamp%2F",
  //   category: "Programming",
  // },
  // {
  //   siteLogo: "../../affiliates/udemy-logo.png",
  //   title: "Reiki Level I, II and Master/Teacher Program",
  //   price: "From $12.99",
  //   imagePath: "../../affiliates/udemy_reiki_1.jpg",
  //   url:
  //     "https://click.linksynergy.com/deeplink?id=m4*xMPdu*tc&mid=39197&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Freikicourse%2F",
  //   category: "Lifestyle",
  // },
  // {
  //   siteLogo: "../../affiliates/udemy-logo.png",
  //   title: "Introduction to Finance and Accounting",
  //   price: "From $12.99",
  //   imagePath: "../../affiliates/udemy_finance_1.jpg",
  //   url:
  //     "https://click.linksynergy.com/deeplink?id=m4*xMPdu*tc&mid=39197&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Fintroduction-to-accounting-finance-modeling-valuation-by-chris-haroun%2F",
  //   category: "Finance",
  // },
  // {
  //   siteLogo: "../../affiliates/udemy-logo.png",
  //   title: "Blockchain and Bitcoin Fundamentals",
  //   price: "From $12.99",
  //   imagePath: "../../affiliates/udemy_blockchain_1.jpg",
  //   url:
  //     "https://click.linksynergy.com/deeplink?id=m4*xMPdu*tc&mid=39197&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Fblockchain-and-bitcoin-fundamentals%2F",
  //   category: "Finance",
  // },
  {
    siteLogo: "../../affiliates/udemy-logo.png",
    title: "The Complete Personal Finance Course",
    price: "From $12.99",
    imagePath: "../../affiliates/udemy_personal_finance_1.jpg",
    url:
      "https://click.linksynergy.com/deeplink?id=m4*xMPdu*tc&mid=39197&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Fthe-complete-personal-finance-course-save-protect-make-more%2F",
    category: "Finance",
  },
  {
    siteLogo: "../../affiliates/udemy-logo.png",
    title: "The Complete Digital Marketing Course",
    price: "From $12.99",
    imagePath: "../../affiliates/udemy_digital_marketing_1.jpg",
    url:
      "https://click.linksynergy.com/deeplink?id=m4*xMPdu*tc&mid=39197&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Flearn-digital-marketing-course%2F",
    category: "Marketing",
  },
  // {
  //   siteLogo: "../../affiliates/udemy-logo.png",
  //   title: "Complete Guide To Instagram Growth",
  //   price: "From $12.99",
  //   imagePath: "../../affiliates/udemy_instagram_1.jpg",
  //   url:
  //     "https://click.linksynergy.com/deeplink?id=m4*xMPdu*tc&mid=39197&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Finstagram-marketing-for-small-businesses%2F",
  //   category: "Finance",
  // },
  {
    siteLogo: "../../affiliates/udemy-logo.png",
    title: "Wordpress for Beginners",
    price: "From $12.99",
    imagePath: "../../affiliates/udemy_wordpress_1.jpg",
    url:
      "https://click.linksynergy.com/deeplink?id=m4*xMPdu*tc&mid=39197&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Fwordpress-for-beginners-course%2F",
    category: "Programming",
  },
  {
    siteLogo: "../../affiliates/udemy-logo.png",
    title: "Learn Photoshop & Web Design",
    price: "From $12.99",
    imagePath: "../../affiliates/udemy_photoshop_1.jpg",
    url:
      "https://click.linksynergy.com/deeplink?id=m4*xMPdu*tc&mid=39197&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Flearn-photoshop-web-design-profitable-freelancing%2F",
    category: "Design",
  },
  // {
  //   siteLogo: "../../affiliates/udemy-logo.png",
  //   title: "New Way To Learn Piano & Keyboard",
  //   price: "From $12.99",
  //   imagePath: "../../affiliates/udemy_piano_1.jpg",
  //   url:
  //     "https://click.linksynergy.com/deeplink?id=m4*xMPdu*tc&mid=39197&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Fpianoforall-incredible-new-way-to-learn-piano-keyboard%2F",
  //   category: "Art",
  // },

  // udacity
  {
    type: "square",
    url: "https://imp.i115008.net/c/2748385/828335/11298",
    content: `<a href="https://imp.i115008.net/c/2748385/828335/11298" target="_blank" id="828335"><img src="//a.impactradius-go.com/display-ad/11298-828335" border="0" alt="" width="300" height="250"/></a><img height="0" width="0" src="https://imp.pxf.io/i/2748385/828335/11298" style="position:absolute;visibility:hidden;" border="0" />`,
    isPremadeBanner: true,
    label: "Udacity Promotion",
    category: "Programming",
  },
  // {
  //   type: "square",
  //   url: "https://imp.i115008.net/c/2748385/895502/11298",
  //   content: `<a href="https://imp.i115008.net/c/2748385/895502/11298" target="_top" id="895502"><img src="//a.impactradius-go.com/display-ad/11298-895502" border="0" alt="" width="300" height="250"/></a><img height="0" width="0" src="https://imp.pxf.io/i/2748385/895502/11298" style="position:absolute;visibility:hidden;" border="0" />`,
  //   isPremadeBanner: true,
  //   label: "Udacity Promotion",
  //   category: "Programming",
  // },
  // {
  //   siteLogo: "../../affiliates/udacity-logo.png",
  //   title: "Learn to code",
  //   price: "60% off",
  //   imagePath:
  //     "https://d20vrrgs8k4bvw.cloudfront.net/images/degrees/nd000/nd-card.jpg",
  //   url:
  //     "https://imp.i115008.net/c/2748385/788805/11298?prodsku=nd000&u=https%3A%2F%2Fwww.udacity.com%2Fcourse%2Fintro-to-programming-nanodegree--nd000&intsrc=PUI2_5678",
  //   category: "Programming",
  // },
  // {
  //   siteLogo: "../../affiliates/udacity-logo.png",
  //   title: "Become a data scientist",
  //   price: "60% off",
  //   imagePath:
  //     "https://d20vrrgs8k4bvw.cloudfront.net/images/degrees/nd025/nd-card.jpg",
  //   url:
  //     "https://imp.i115008.net/c/2748385/788805/11298?prodsku=nd025&u=https%3A%2F%2Fwww.udacity.com%2Fcourse%2Fdata-scientist-nanodegree--nd025&intsrc=PUI2_5678",
  //   category: "Programming",
  // },

  // coursera
];
