import React, { Component } from "react";

export default class AudioPlayer extends Component {
  constructor(props) {
    super(props);

    this.audio = null;

    this.state = {
      isPlaying: false,
    };

    this.toggleAudio = () => {
      if (this.state.isPlaying) {
        this.pauseAudio();
      } else {
        this.playAudio();
      }
    };
    this.playAudio = () => {
      if (!this.audio && typeof Audio !== "undefined") {
        // call new Audio only once and not on load
        this.audio = new Audio(props.src);
      }
      this.setState({
        isPlaying: true,
      });
      this.audio.play();
    };

    this.pauseAudio = () => {
      this.setState({
        isPlaying: false,
      });
      this.audio.pause();
    };
  }

  componentDidMount() {
    this.setState({
      isPlaying: false,
    });
  }

  render() {
    return (
      <button className="audio-button" onClick={() => this.toggleAudio()}>
        {this.state.isPlaying ? <span>⏸</span> : <span>▶️</span>}
      </button>
    );
  }
}
