import PreorderForm from "../../../../src/components/PreorderForm";
import AudioPlayer from "../../../../src/components/AudioPlayer";
import Strawpoll from "../../../../src/components/Strawpoll";
import InlineRelated from "../../../../src/components/InlineRelated";
import LazyIframe from "../../../../src/components/LazyIframe";
import YoutubePlayer from "../../../../src/components/YoutubePlayer";
import InlineAd from "../../../../src/components/InlineAd";
import * as React from 'react';
export default {
  PreorderForm,
  AudioPlayer,
  Strawpoll,
  InlineRelated,
  LazyIframe,
  YoutubePlayer,
  InlineAd,
  React
};