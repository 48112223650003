import React, { Component } from 'react';
const axios = require('axios').default;
import preoderImage from '../../src/images/120-conversation-phrases-ipad.png';

export default class PreorderForm extends Component {
	constructor(props) {
		super(props);

		this.handleSubmit = async (e) => {
			e.preventDefault();
			const emailField = document.querySelector('#preorder-email');
			const email = emailField.value;
			const nameField = document.querySelector('#preorder-name');
			const name = nameField.value;

			try {
				await axios.post(
					`https://langi.app/api/preauth/addToHowToVietnamese`,
					{
						params: {
							email,
							name,
							type: '120_conv_phrases',
						},
					},
					{
						headers: {
							authorization: 'howtovietnamese!',
						},
					}
				);
				alert('Thank you');
				emailField.value = '';
				nameField.value = '';
			} catch (error) {
				alert('There was an error, please try again');
			}
		};
	}

	render() {
		return (
			<div className="container pl-2 pr-2">
				<hr />
				<div className="row d-flex justify-content-center">
					<h4 className="text-blue">Free eBook</h4>
					<h3 className="mb-1 text-align-center">
						120 Useful Conversational Phrases & Words in Vietnamese
					</h3>
				</div>
				<img src={preoderImage} class="img-fluid" alt="Responsive image"></img>
				<p className="text-muted mt-2">
					Join our newsletter and get this eBook for free to kickstart your
					Vietnamese language learning!
				</p>
				<form onSubmit={this.handleSubmit}>
					<div className="form-row mt-1">
						<div className="col-md-4 mb-1">
							<input
								type="email"
								className="form-control"
								id="preorder-email"
								placeholder="Your Email"
							/>
						</div>
						<div className="col-md-4 mb-1">
							<input
								type="text"
								className="form-control"
								id="preorder-name"
								placeholder="Your Name"
							/>
						</div>
						<div className="col-md-3">
							<button type="submit" className="btn-sm btn-yellow">
								Count Me In!
							</button>
						</div>
						{/* <small className="form-text mt-1">
                        ⇢ <Link className="text-white" to="/course-vietnamese-phrases-for-expats-travelers" target="_blank"> View Course Details</Link>
                        </small> */}
					</div>
				</form>
				<hr class="mb-0" />
			</div>
		);
	}
}
