import Link from "gatsby-link";
import PromoBanner from "../../../../src/components/PromoBanner";
import InlineRelated from "../../../../src/components/InlineRelated";
import InlineAffiliateAd from "../../../../src/components/InlineAffiliateAd";
import InlineAd from "../../../../src/components/InlineAd";
import YoutubePlayer from "../../../../src/components/YoutubePlayer";
import * as React from 'react';
export default {
  Link,
  PromoBanner,
  InlineRelated,
  InlineAffiliateAd,
  InlineAd,
  YoutubePlayer,
  React
};