import Link from 'gatsby-link';
import PreorderForm from "../../../../src/components/PreorderForm";
import InlineRelated from "../../../../src/components/InlineRelated";
import InlineAd from "../../../../src/components/InlineAd";
import * as React from 'react';
export default {
  Link,
  PreorderForm,
  InlineRelated,
  InlineAd,
  React
};