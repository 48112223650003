import PreorderForm from "../../../../src/components/PreorderForm";
import PromoBanner from "../../../../src/components/PromoBanner";
import InlineAd from "../../../../src/components/InlineAd";
import InlineAffiliateAd from "../../../../src/components/InlineAffiliateAd";
import * as React from 'react';
export default {
  PreorderForm,
  PromoBanner,
  InlineAd,
  InlineAffiliateAd,
  React
};