import PreorderForm from "../../../../src/components/PreorderForm";
import PromoBanner from "../../../../src/components/PromoBanner";
import InlineAd from "../../../../src/components/InlineAd";
import InlineRelated from "../../../../src/components/InlineRelated";
import InlineAffiliateAd from "../../../../src/components/InlineAffiliateAd";
import Strawpoll from "../../../../src/components/Strawpoll";
import * as React from 'react';
export default {
  PreorderForm,
  PromoBanner,
  InlineAd,
  InlineRelated,
  InlineAffiliateAd,
  Strawpoll,
  React
};