import React, { useEffect, useState } from "react";

// TODO: PROBLEM HERE IS THAT THE IFRAME GET'S RE RENDERED, MAKING MY CLASS HIDDEN USELESS
function LazyIframe(props) {
  const [frame, setState] = useState([]);

  useEffect(() => {
    setState({
      width: props.width,
      height: props.height,
      frameBorder: props.frameBorder,
      allowtransparency: props.allowtransparency === "true" ? true : false,
      allow: props.allow,
      class: props.className ? props.className : "",
      src: props.src,
    });
  }, []);

  return (
    <iframe
      src={frame.src}
      loading="lazy"
      ref={frame.myRef}
      width={frame.width}
      height={frame.height}
      frameBorder={frame.frameBorder}
      allowtransparency={frame.allowtransparency}
      allow={frame.allow}
    ></iframe>
  );
}

export default LazyIframe;
