// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-index-jsx": () => import("./../../../src/pages/contact/index.jsx" /* webpackChunkName: "component---src-pages-contact-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-learning-jsx": () => import("./../../../src/pages/learning.jsx" /* webpackChunkName: "component---src-pages-learning-jsx" */),
  "component---src-pages-old-vietnamese-essential-beginner-phrases-jsx": () => import("./../../../src/pages/old-vietnamese-essential-beginner-phrases.jsx" /* webpackChunkName: "component---src-pages-old-vietnamese-essential-beginner-phrases-jsx" */),
  "component---src-pages-old-vietnamese-essential-phrases-beginners-jsx": () => import("./../../../src/pages/old-vietnamese-essential-phrases-beginners.jsx" /* webpackChunkName: "component---src-pages-old-vietnamese-essential-phrases-beginners-jsx" */),
  "component---src-pages-podcast-jsx": () => import("./../../../src/pages/podcast.jsx" /* webpackChunkName: "component---src-pages-podcast-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-vietnamese-1000-essential-vocabulary-jsx": () => import("./../../../src/pages/vietnamese-1000-essential-vocabulary.jsx" /* webpackChunkName: "component---src-pages-vietnamese-1000-essential-vocabulary-jsx" */),
  "component---src-pages-vietnamese-1000-essential-vocabulary-jsx-jsx": () => import("./../../../src/pages/vietnamese-1000-essential-vocabulary.jsx .jsx" /* webpackChunkName: "component---src-pages-vietnamese-1000-essential-vocabulary-jsx-jsx" */),
  "component---src-pages-vietnamese-1000-vocabulary-jsx": () => import("./../../../src/pages/vietnamese-1000-vocabulary.jsx" /* webpackChunkName: "component---src-pages-vietnamese-1000-vocabulary-jsx" */),
  "component---src-pages-vietnamese-easy-conversation-phrases-dummy-jsx": () => import("./../../../src/pages/vietnamese-easy-conversation-phrases-dummy.jsx" /* webpackChunkName: "component---src-pages-vietnamese-easy-conversation-phrases-dummy-jsx" */),
  "component---src-pages-vietnamese-ebook-bundle-jsx": () => import("./../../../src/pages/vietnamese-ebook-bundle.jsx" /* webpackChunkName: "component---src-pages-vietnamese-ebook-bundle-jsx" */),
  "component---src-pages-vietnamese-grammar-book-bundle-jsx": () => import("./../../../src/pages/vietnamese-grammar-book-bundle.jsx" /* webpackChunkName: "component---src-pages-vietnamese-grammar-book-bundle-jsx" */),
  "component---src-pages-vietnamese-listening-practice-jsx": () => import("./../../../src/pages/vietnamese-listening-practice.jsx" /* webpackChunkName: "component---src-pages-vietnamese-listening-practice-jsx" */),
  "component---src-pages-vietnamese-numbers-jsx": () => import("./../../../src/pages/vietnamese-numbers.jsx" /* webpackChunkName: "component---src-pages-vietnamese-numbers-jsx" */),
  "component---src-pages-vietnamese-self-study-bundle-jsx": () => import("./../../../src/pages/vietnamese-selfStudy-bundle.jsx" /* webpackChunkName: "component---src-pages-vietnamese-self-study-bundle-jsx" */),
  "component---src-pages-vietnamese-sentence-patterns-book-1-jsx": () => import("./../../../src/pages/vietnamese-sentence-patterns-book-1.jsx" /* webpackChunkName: "component---src-pages-vietnamese-sentence-patterns-book-1-jsx" */),
  "component---src-pages-vietnamese-sentence-patterns-book-2-audio-jsx": () => import("./../../../src/pages/vietnamese-sentence-patterns-book-2-audio.jsx" /* webpackChunkName: "component---src-pages-vietnamese-sentence-patterns-book-2-audio-jsx" */),
  "component---src-pages-vietnamese-sentence-patterns-book-2-jsx": () => import("./../../../src/pages/vietnamese-sentence-patterns-book-2.jsx" /* webpackChunkName: "component---src-pages-vietnamese-sentence-patterns-book-2-jsx" */),
  "component---src-pages-vietnamese-survival-course-jsx": () => import("./../../../src/pages/vietnamese-survival-course.jsx" /* webpackChunkName: "component---src-pages-vietnamese-survival-course-jsx" */),
  "component---src-pages-vietnamese-vocabulary-builder-book-1-jsx": () => import("./../../../src/pages/vietnamese-vocabulary-builder-book-1.jsx" /* webpackChunkName: "component---src-pages-vietnamese-vocabulary-builder-book-1-jsx" */),
  "component---src-pages-youtube-jsx": () => import("./../../../src/pages/youtube.jsx" /* webpackChunkName: "component---src-pages-youtube-jsx" */),
  "component---src-templates-blogpost-template-jsx": () => import("./../../../src/templates/blogpost-template.jsx" /* webpackChunkName: "component---src-templates-blogpost-template-jsx" */),
  "component---src-templates-course-details-template-jsx": () => import("./../../../src/templates/course-details-template.jsx" /* webpackChunkName: "component---src-templates-course-details-template-jsx" */),
  "component---src-templates-course-template-jsx": () => import("./../../../src/templates/course-template.jsx" /* webpackChunkName: "component---src-templates-course-template-jsx" */)
}

