import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import { Link, navigate } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import React, { useEffect } from 'react';

const promoBannerStyle = {
	display: 'none',
	height: '60px',
	position: 'fixed',
	zIndex: '99',
	//top: "60px",
	marginLeft: '0',
	marginRight: '0',
	//background: "linear-gradient(90.04deg,rgb(251, 177, 70) 0%, rgb(237, 222, 93) 68.85%)",
	background: '#faebeb',
	transition: 'left 0.5s ease 0s',
};

const buttonStyle = {
	height: '40px',
	display: 'flex',
	flexDirection: 'row',
	textDecoration: 'none',
	fontSize: '12px',
	letterSpacing: '0.8px',
	background: '#111112',
	border: '1px solid #030d51',
	borderRadius: '4px',
};

// https://www.epochconverter.com/
// remember to always update the date with the current timestamp taken from the above site
const promo = {
	title: 'Enjoy Black Friday 40% OFF storewide with code BF2024',
	url: '/learning',
	dateCreated: 1700289821,
	expiryDate: 1733227200,
};

function PromoBanner() {
	const storageName = 'promoBanner';
	const now = new Date().getTime();
	// const oneDayAgo = 86400000;
	// const fourDaysAgo = now - oneDayAgo * 4;

	// uncomment to show again
	// useEffect(() => {
	// 	setTimeout(() => {
	// 		attemptOpenBanner();
	// 	}, 200);
	// }, []);
	const showBanner = () => {
		if (typeof window !== 'undefined') {
			const bannerContainer = document.querySelector('#promoBanner');
			bannerContainer.style.display = 'flex';
			// when this one opens, remove the class from the post container to void having too much padding
			const contentContainer = document.querySelector('.contentContainer');
			if (contentContainer) {
				contentContainer.classList.remove('margin-from-header');
				// double the offset
				contentContainer.classList.add('extra-margin-from-header');
			}
		}
	};

	const hideBanner = () => {
		const bannerContainer = document.querySelector('#promoBanner');
		bannerContainer.style.display = 'none';
		// when this one closes, add the class from the post container to have the correct margin from the header
		const contentContainer = document.querySelector('.contentContainer');
		if (contentContainer) {
			contentContainer.classList.add('margin-from-header');
			// remove the double offset
			contentContainer.classList.remove('extra-margin-from-header');
		}
	};

	const getStorage = () => {
		if (typeof window !== 'undefined') {
			const storage = localStorage.getItem(storageName);
			if (storage) {
				return JSON.parse(storage);
			}
		}
	};
	const canOpenBanner = () => {
		const storage = getStorage();

		if (promo.expiryDate * 1000 < now) {
			return false;
		} else {
			if (!storage || promo.url !== storage.url || !storage.lastOpened) {
				// either it's the first time or we updated the promo, we show
				return true;
			} else {
				return false;
			}
		}
		// // first time, new banner OR 3 days have passed
		// if (promo.dateCreated * 1000 < fourDaysAgo) {
		// 	// the banner is more than 4 days old, we stop showing it even if they didn't read it
		// 	return false;
		// }
		// // legacy fallback for old storage
		// if (storage && storage.promoUrl) {
		// 	storage.url = storage.promoUrl;
		// }
	};
	const markPromoAsViewed = () => {
		let storage = getStorage();
		if (storage) {
			storage.url = promo.url;
			storage.lastOpened = now;
			// update
		} else {
			// set
			storage = {
				url: promo.url,
				lastOpened: now,
			};
		}
		localStorage.setItem(storageName, JSON.stringify(storage));
	};

	const closeBanner = () => {
		// close banner
		hideBanner();
		markPromoAsViewed();
	};

	const trackEvent = (e, route) => {
		e.preventDefault();
		trackCustomEvent({
			// track the click
			category: 'promot_banner',
			action: 'Click',
			label: promo.title,
			value: promo.url,
		});
		navigate(route);
		closeBanner();
	};

	const attemptOpenBanner = () => {
		if (canOpenBanner()) {
			showBanner();
		}
	};
	return (
		<div className="row" style={promoBannerStyle} id="promoBanner">
			<div className="col-12 col-md-6 align-center pr-0">
				<p className="pr-1 pr-md-2 pl-0 mb-0 promoBanner-text font-weight-bold">
					{promo.title}
				</p>
				{/* <OutboundLink
          target="_blank"
          style={buttonStyle}
          className="promoBanner-btn box-shadow align-center"
          href={promo.url}
        >
          View Now
        </OutboundLink> */}
				<Link
					onClick={(e) => trackEvent(e, promo.url)}
					to={promo.url}
					style={buttonStyle}
					className="promoBanner-btn box-shadow align-center"
				>
					View NOW
				</Link>

				<button onClick={() => closeBanner()}>X</button>
			</div>
		</div>
	);
}

export default PromoBanner;
