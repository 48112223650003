import React from "react";
import AdSense from "react-adsense";

const AdArticle = () => {
  return (
    <React.Fragment>
      <br/>
      <AdSense.Google
        class="mt-2 mb-2"
        client="ca-pub-9009050054990613"
        slot="9102995091"
        style={{ display: "block" }}
        layout="in-article"
        format="fluid"
      />
    </React.Fragment>
  );
};

export default AdArticle;
