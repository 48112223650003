import React from "react";

const Title = props => {
    const title = props.title;
    return (
        <div className="title">
            <h2 className="section-heading-title">{title}</h2>
        </div>
    )
}

export default Title