import LazyIframe from "../../../../src/components/LazyIframe";
import Link from "gatsby-link";
import InlineRelated from "../../../../src/components/InlineRelated";
import InlineAffiliateAd from "../../../../src/components/InlineAffiliateAd";
import YoutubePlayer from "../../../../src/components/YoutubePlayer";
import * as React from 'react';
export default {
  LazyIframe,
  Link,
  InlineRelated,
  InlineAffiliateAd,
  YoutubePlayer,
  React
};