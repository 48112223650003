import React from "react";

function Strawpoll() {
  return (
    <iframe
      width="100%"
      height="550"
      src="https://strawpoll.com/embed/9w3qgrobd"
      frameborder="0"
      allowfullscreen
    ></iframe>
  );
}

export default Strawpoll;
