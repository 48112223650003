import React, { Component } from "react";
import AdImage from "../images/bannerAdBooks.png";
import Link from "gatsby-link";

const inlineAdStyle = {
  width: "300px",
  height: "250px",
};
export default class InlineAd extends Component {
  render() {
    return (
      <div className="row align-center mt-5 mb-5 ">
        <Link className="col-10 col-md-6 pl-0 pr-0" to="/bookstore">
          <img
            style={inlineAdStyle}
            className="box-shadow"
            src={AdImage}
            alt="Book Library Imag"
          ></img>
        </Link>
      </div>
    );
  }
}
