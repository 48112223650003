import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { Link } from "gatsby";

const InlineRelated = (props) => (
  <StaticQuery
    query={graphql`
      query {
        allMdx(sort: { order: DESC, fields: [frontmatter___date] }) {
          edges {
            node {
              id
              frontmatter {
                title
                excerpt
                date
                path
                category
                image {
                  childImageSharp {
                    fluid(maxWidth: 900) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const blogID = props.path;
      const posts = data.allMdx.edges;
      const post = posts.find((edge) => edge.node.frontmatter.path === blogID)
        .node.frontmatter;
      const link = `https://howtovietnamese.com/${post.path}`;
      return (
        <a target="_blank" className="inline_related_card " href={link}>
          <div className="inline_related_title">
            <span>Related</span>
          </div>
          <Img
            className="inline_related_image"
            fluid={post.image.childImageSharp.fluid}
            alt={post.title}
          />
          <a target="_blank" href={link} className="inline_related_text">
            {post.title}
          </a>
        </a>
      );
    }}
  />
);

export default InlineRelated;
